import * as _ from 'lodash'
import {
  PAYMENT_RESTRICTION,
  PREMIUM_RESTRICTIONS_ENDPOINT,
  PLATFORMIZED_FORMS_URL,
} from './constants'
import { get } from './fetch-utils'

// this method is being used only in preview mode in editor although imported in viewer-app
export const isPaymentAllowed = async () => {
  let allowed = false

  try {
    const oldRestrictions = await get(PLATFORMIZED_FORMS_URL, PREMIUM_RESTRICTIONS_ENDPOINT)
    const allowedFeatures = _.get(oldRestrictions, 'allowedFeatures')
    allowed = _.includes(allowedFeatures, PAYMENT_RESTRICTION)
  } catch {}

  return allowed
}
